import React, { useState, useEffect } from 'react';
import Spinner from './components/Spinner';
import QuestionInput from './components/SubmitQuestion';
import './App.css';

const FAQ = () => {
  const [loading, setLoading] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [questions, setQuestions] = useState([
    // {id: 0, question: '', answer: ''}
  ]);
  const [newQuestion, setNewQuestion] = useState('');

  const getSessionData = async () => {
    try {
      let url = '/chatbot/getsession/';
      if (process.env.NODE_ENV === 'development') {
        // Use port 8000 in the development environment
        url = 'http://localhost:8000/chatbot/getsession/';
      }
      const response = await fetch(url, {
        method: 'GET',
        //credentials: 'include', // Include credentials to send cookies
      });

      if (response.ok) {
        const data = await response.json();
        setSessionId(data.session_id);
      } else {
        // Handle errors
        console.error('Error fetching session data:', response.statusText);
      }
    } catch (error) {
      // Handle network errors
      console.error('Network error:', error);
    }
  };

  useEffect(() => {
    getSessionData();
  }, []);

  const handleReset = (e) => {
    setQuestions([]);
    setNewQuestion('');
  };

  const handleQuestionChange = (e) => {
    setNewQuestion(e);
  };

  // added for CSRF support but ended up turning off for dev (can be removed)
  const getCookie = (name) => {
    const cookieString = document.cookie; // returns all cookies
    const cookies = cookieString.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1, cookie.length);
      }
    }
    // if not found
    return null;
  };

  const submitForm = async () => {
    try {
      setLoading(true);
      let url = '/chatbot/question/';
      if (process.env.NODE_ENV === 'development') {
        // Use port 8000 in the development environment
        url = 'http://localhost:8000/chatbot/question/';
      }
      // csrf is not really required due to nature of the app 
      // keeping for good practice
      // currently turned off at endpoint for dev (not working with two webservers - node and django)
      // putting in header was an attempt to resolve two server issue - could not get working
      const csrftoken = getCookie('csrftoken');
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrftoken,
          'Session-ID': sessionId,
        },
        body: JSON.stringify({ ct: questions.length + 1, question: newQuestion }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const newQues = {
        id: questions.length + 1,
        question: data.question,
        answer: data.answer,
      };
      setQuestions([...questions, newQues]);
      setNewQuestion('');

    } catch (error) {
      console.error('There was an error:', error);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <div className="content">
        <h2>How can I help you today?</h2>
        <ul>
          {questions.map((q) => (
            <li key={q.id}>
              <div className="question"><i>Q: {q.question}</i></div>
              <div dangerouslySetInnerHTML={{ __html: q.answer }} />
            </li>
          ))}
        </ul>
        <QuestionInput 
          value={newQuestion}
          onUpdate={handleQuestionChange}
          onSubmit={submitForm}
        />
        <div className="reset-btn-container">
          <button className="reset-btn" onClick={handleReset}>
            Reset
          </button>
          {loading ? (<Spinner />) : ""}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
