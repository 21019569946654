// SubmitQuestion.js

import React, { useRef, useEffect } from 'react';

const SubmitQuestion = ({ value, onUpdate, onChange, onSubmit }) => {
  const refQuestionInput = useRef(null);

  const adjustTextAreaHeight = () => {
    if (refQuestionInput.current) {
      refQuestionInput.current.style.height = 'auto';
      refQuestionInput.current.style.height = `${refQuestionInput.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextAreaHeight();
  }, [value]);

  const handleQuestionChange = (e) => {
    adjustTextAreaHeight();
    onUpdate(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      //e.stopPropagation();
      e.preventDefault();
      onSubmit();
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const onlySpaces = /^\s*$/;
    if (onlySpaces.test(value)) {
      onUpdate(''); // Clear textarea directly
      refQuestionInput.current.focus();
    } else {
      const trimmedValue = value.trim();
      onUpdate(trimmedValue); // Update value directly
      onSubmit();
    }
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div className="submit-question-wrapper">
        <textarea
          ref={refQuestionInput}
          className="submit-question-input"
          placeholder="Input question here"
          rows="1"
          value={value}
          onChange={handleQuestionChange}
          onKeyDown={handleKeyDown}
        ></textarea>
        <button className="submit-submit-button" type="submit">
          ?
        </button>
      </div>
    </form>
  );
};

export default SubmitQuestion;
